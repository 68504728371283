import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useGetUserQuery } from "./redux/features/api/userApi";
import { status, pending } from "./redux/features/authSlice";
import Login from "./containers/Login";
import Calendar from "./containers/Calendar";
import Dashboard from "./containers/Dashboard";
import CustomSpinner from "./components/CustomSpinner";
import { Grid } from "antd-mobile/es";
import "./style.css";

const privatRoutes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/calendar",
    component: Calendar,
  },
];

const App = (props) => {
  const isAuth = useSelector(status);
  const authPending = useSelector(pending);

  const { userPending } = useGetUserQuery(undefined, {
    selectFromResult: ({ isFetching }) => ({
      userPending: isFetching
    })
  });

  useEffect(() => {
    if (!isAuth && !authPending) {
      props.history.push("/login");
    }
    if (isAuth && !authPending && props.history.location.pathname === "/login") {
      props.history.push("/");
    }
  }, [isAuth, authPending]);

  const loading = (<Grid columns={1} className="main-spinner">
    <CustomSpinner size="15px" withText />
  </Grid>);

  return (
    <Switch>
      {privatRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={authPending ? () => loading : route.component}
        />
      ))}

      <Route path="/login" component={(userPending && authPending) ? () => loading : Login} />
    </Switch>
  );
}


export default withRouter(withTranslation()(App));
